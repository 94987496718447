/* Shopping cart container */
.cart .shopping-cart{
    margin-bottom: 2rem;
    overflow-x: auto;
    overflow-y: hidden; 
    border: var(--border); 
} 

/* Shopping cart container Scrollbar */
.cart .shopping-cart::-webkit-scrollbar{  
    height: 1rem; 
}  

/* Shopping cart inside Container */
.cart .shopping-cart .container{
    min-width: 90rem;  
}

/* Cart item */
.cart-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem;
    gap: 3rem;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
} 

/* Cart item last child */
.cart-item:last-child{
    border: none;
}

/* Cart item Box */
.cart-item .box{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 15rem;
        flex: 1 1 15rem;
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
    color: var(--grey);
}

/* Cart item Product Container */
.cart-item .product{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 25rem; 
        flex: 1 1 25rem;  
    display: -webkit-box;
    display: -ms-flexbox; 
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    gap: 1rem;
}

/* Cart item Image */
.cart-item img{
    height: 10rem;
    width: 10rem;
    object-fit: cover;
    background-color:#f7f7f7;
}

/* Cart item Name */
.cart-item .name{
    color: var(--black);
    font-weight: 500;
}

/* Cart item Action Container */
.cart-item .action{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* Cart item Action Button */
.cart-item button{
    background-color: transparent; 
}

/* Cart item Icon */
.cart-item .icon{
    font-size: 2.5rem;
    cursor: pointer;
    color: red;
}

/* Cart item Icon, Hover Effect */
.cart-item .icon:hover{
    color: var(--secondary-color); 
}

/* Cart total */
.cart .cart-total{
    width: 45rem; 
    margin-left: auto;
} 

/* Cart total summary */
.cart .shop-summary{
    padding: 1rem 1.5rem;
    border: var(--border);
}
 
/* Cart total Button */
.cart .cart-total .btn{
    width: 100%;
    text-align: center;
    margin: 1rem 0;
}


/********** Quantity Box **********/
/* Quantity box Container  */
.quantity {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
    
  /* Quantity Input field */
  .quantity .input-text { 
    width: 3.5rem;
    height: 4rem;
    padding: 0 5px;
    text-align: center;
    background-color: transparent;
    border: 1px solid #ccc;
  }
  
  /* Minus and Plus Buttons */
  .quantity .minus,
  .quantity .plus {
    padding: 7px 10px 8px;
    height: 4rem;
    background-color: #ffffff;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  /* Minus button */
  .quantity .minus {
    border-right: 0;
    color: var(--black);
  }
  
  /* Plus button */
  .quantity .plus {
    border-left: 0;
    color: var(--black);
  }
  
  /* Minus and Plus buttons, Hover Effect */
  .quantity .minus:hover,
  .quantity .plus:hover {
    background: var(--main-color);
    color: var(--white);
  }
  
  /* Remove default spin button appearance */
  .quantity input::-webkit-outer-spin-button,
  .quantity input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  
  /* Remove focus outline for minus and plus buttons */
  .quantity .minus:focus,
  .quantity .plus:focus {
    outline: none;
  }  