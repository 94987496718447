/* Point Table Section */
.points-container{
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
}
 
/* Table title */
.point-table .table-title{ 
  background-color: var(--black);
  border-bottom: 0.4rem solid var(--main-color);
  color: var(--white);
  display: -webkit-box; 
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem 0.5rem;
  gap: 0.5rem;
}

/* Team Name Column headers */
.point-table .table-title h3.name{
  -webkit-box-flex: 1;
  -ms-flex: 1 1 30rem;
      flex: 1 1 30rem;
}

/* Table Column headers */
.point-table .table-title h3{
  -webkit-box-flex: 1;
  -ms-flex: 1 1 5rem;
      flex: 1 1 5rem;
  font-size: 1.8rem;
  text-align: center;
}

/* Points section */
.point-table .points{
  background-color: var(--white);
  padding: 0rem 0.5rem;
}

/* Each Point Item row */
.point-item{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem 0;
  gap: 0.5rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
}

/* Individual boxes in each row */
.point-item .box{
  -webkit-box-flex: 1;
  -ms-flex: 1 1 5rem;
      flex: 1 1 5rem;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--grey);
}

/* Team Name Column in each row */
.point-item .name{
  -webkit-box-flex: 1;
  -ms-flex: 1 1 30rem; 
      flex: 1 1 30rem;  
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
  gap: 1rem;
}

/* Team Logo */
.point-item .name img{
  height: 3.5rem;
  width: 3.5rem;
  object-fit: cover;
}

/* Team Name */
.point-item .name span{ 
  color: var(--black);
  font-weight: 500;
  font-size: 1.8rem;
}