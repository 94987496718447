/* Button style */
.btn {
	display: inline-block;
	color: var(--white); 
	background: var(--main-color);
	font-size: 1.8rem;
	font-weight: 500;
	letter-spacing: 1px;  
	padding: 1.2rem 3rem;  
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out; 
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	-webkit-box-shadow: var(--box-shadow);
			box-shadow: var(--box-shadow);
}

/* Button style, On hover */
.btn:hover {
	background-color: var(--secondary-color);
	cursor: pointer;
}