/* Product container */
.product-item{
  overflow: hidden;
  -webkit-transition: all 1s ease;  
  -moz-transition: all 1s ease; 
  -o-transition: all 1s ease;  
  transition: all 1s ease; 
}  
 
/* Image container */ 
.product-item .image {  
  position: relative;
}

/* Cart Link */
.product-item .image a{
  position: absolute;
  top: 1rem;
  left: -100%;
  height: 4rem;
  width: 4rem;
  font-size: 2rem;
  cursor: pointer;
  color: var(--white);
  background-color: var(--main-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; 
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);
          clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);
}

/* Cart Link, On Hover */
.product-item .image a:hover{
  background-color: var(--secondary-color);
}

/* Show Cart Link, On Product Hover */
.product-item:hover .image a{
  left: 1rem;
}

/* Product image */
.product-item .image img{
  width: 100%;
  height: 25rem;
  object-fit: cover;
  border: var(--border);
}

/* Product content */
.product-item .content {
  padding: 1.5rem 0;
}

/* Product rating */
.product-item .rating {
  padding-top: 0.7rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

/* Product Rating icon */
.product-item .rating .icon {
  color: var(--main-color);
  font-size: 2rem;
}

/* Product Rating text */
.product-item .rating h5 {
  font-size: 1.5rem;
  color: var(--black);
} 

/* Product name */
.product-item h3 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--black);
  padding-bottom: 0.5rem;
}

/* Product name, On Hover */
.product-item h3:hover {
  color: var(--main-color);
}

/* Product price */
.product-item .price {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--main-color);
}

/* Product Discounted price */
.product-item .price span {
  font-size: 1.4rem;
  font-weight: 400;
  text-decoration: line-through;
  color: var(--grey);
  padding-left: 0.2rem;
} 