/* Header Section */
.header{
  position: fixed;
  top: 0;
  width: 100%;
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  z-index: 1000;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out; 
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
} 
 
/* Header 1 */
.header .header-1{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #171717;
  padding: 0.5rem 5%;
}

/* Header-1, On Header Active */
.header.active .header-1{
  display: none;
}

/* Header contacts */
.header .header-contacts{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1rem;
  list-style-type: none;
}

/* Header contact */
.header .header-contacts a{
  height: 3rem;
  width: 3rem;
  font-size: 1.6rem;
  color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; 
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* Header contact, On Hover */
.header .header-contacts a:hover{
  color: var(--main-color);
}

/* Header-2 */
.header .header-2{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 7rem;
  padding: 0rem 5%;
  background: var(--main-color);
}

/* Header Logo */
.header .logo{
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -ms-grid-row-align: flex-start;
  align-items: flex-start;
}

/* Header Navbar */
.header .navbar{
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  gap: 2.5rem;
}

/* Header Icon Container */
.header .icon-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1.5rem;
}

/* Header Icon Link */
.header .icon-container .icon{
  font-size: 2.5rem;
  color: var(--white);
  cursor: pointer;
}

/* Header Icon Link, On Hover */
.header .icon-container .icon:hover{
  color: var(--black);
}

/* Header Menu Button */
.header .menu-btn{
  display: none;
}

/* Header Cart Link */
.header .cart-icon {
  position: relative;
}

/* Header Cart Link Badge */
.header .cart-icon .badge {
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 2rem;
  height: 2rem;
  background-color: var(--black);
  color: #fff;
  border-radius: 50%;
  font-size: 1.2rem;
}

/* Mobile Menu */
.mobile-menu{
  display: none;
}

/* Responsive Styles */
@media (max-width: 991px) {

  /* Header Menu Button (Visible on Mobile) */
  .header .menu-btn {
    display: block; 
  } 

  /* Mobile Menu (Visible on Mobile) */
  .mobile-menu {
    display: block;
  }

  /* Header Navbar (Hidden on Mobile) */
  .header .navbar {
    display: none;
  }
}  