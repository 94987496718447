/* Empty cart Section */
.empty-cart{
    height: 70vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

/* Empty cart Section Icon */
.empty-cart .icon{ 
    font-size: 15rem;
} 

/* Empty cart Section Heading */
.empty-cart h3{
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--black);
    padding: 2rem 0;
}