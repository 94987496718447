/* Team member */
.team-item{
  overflow: hidden;
  position: relative;
  border-bottom: 0.4rem solid var(--main-color);
}
  
/* Team member image container */
.team-item .image{
  height: 35rem; 
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
}

/* Team member image */
.team-item .image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Social media icons Container */
.team-item .icon-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; 
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  padding-bottom: 4rem;
  position: absolute;
  top: 0;
  right: -100%;
  background-color: var(--main-color);
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 80%);
          clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 80%);
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  z-index: 2;
}

/* Social media icons Container, On Team Member Hover */
.team-item:hover .icon-container{
  right: 0;
}

/* Social media icon */
.team-item .icon-container a{
  height: 3.5rem;
  width: 3.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;  
  font-size: 1.7rem;
  color: var(--white);
}

/* Social Media icon, On Hover */
.team-item .icon-container a:hover{
  color: var(--black);
}

/* Team member Content */
.team-item .content{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 2.5rem 1.5rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center; 
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

/* Team member Name */
.team-item .content h2{
  color: var(--white);
  font-size: 2.5rem;
  font-weight: 600;
}

/* Team member Name, On Hover */
.team-item .content a h2:hover{
  color: var(--main-color);
}

/* Team member Title */
.team-item .content h5{
  color: var(--white);
  font-size: 1.8rem;
  font-weight: 500;
}